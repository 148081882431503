import React, { useEffect } from 'react'

import { setCookie } from 'inter-frontend-lib-util'

import { ACCOUNT_KEY } from '@/common/constants'
// import { Inter } from '@/assets/icons'

import * as S from './styles'

const Main = () => {
  useEffect(() => {
    const accountCookie = `${ACCOUNT_KEY}= ; expires = Fri Sep 16 1994 00:00:00 GMT`
    return () => setCookie(accountCookie)
  }, [])

  return <S.Container role="main">Main</S.Container>
}

export default Main
