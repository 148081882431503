/* istanbul ignore file */
import React from 'react'

import ReactDOM from 'react-dom'

import { IS_DEVELOPMENT } from '@/common/constants'

// import AppProviders from './contexts'
import Routes from './routes'
import './styles/global.scss'

if (IS_DEVELOPMENT) {
  import('./common/mocks/handlers')
}

const App = () => (
  // <AppProviders>
  <Routes />
  // </AppProviders>
)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
